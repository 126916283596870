import React from "react"
import {
    Grid,
    Button,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'

import { Link } from "gatsby"


import searchIcon from "@images/search.svg"
import transferIcon from "@images/transfer.svg"
import apiIcon from "@images/api.svg"
import serverIcon from "@images/server.svg"
import cloudProvidersIcon from "@images/cloudicons.png"
import analyticsIcon from "@images/analytics.svg"
import overview from "@images/b2boverview.svg"
import styles from "./features.css"

//import Layout from "@components/layout"
import B2BLogo from "@images/B2BIntegratorLogo.svg"
import HeaderRow from "../components/headerRow.js"


const FeaturePage =({data}) => {
    const [visible, setVisible] = React.useState(false)
    const sayHello = () => {
        setVisible(!visible);
      };
    return (
        <Grid>
        <Grid.Row columns={2}>
            <Grid.Column mobile={14} computer={8}>
                <Image src={B2BLogo} as='a' href='/'/>
            </Grid.Column>
            <Grid.Column only="computer" computer={8}>
                <Menu secondary>
                    <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
                    <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                    <Menu.Item name='resources'>Resources</Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item as={Link} name='login' to='/login'>Login</Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Grid.Column>
            <Grid.Column only="tablet mobile" mobile={2}>
                <Menu secondary floated='right' >
                    <Menu.Item >
                        <Icon onClick={() => {sayHello()}} name="sidebar"></Icon>
                    </Menu.Item>
                </Menu>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        direction="right"
                        onHide={() => setVisible(false)}
                        vertical
                        visible={visible}
                        width='thin'
                        inverted
                    >
                        <Menu.Item  as={Link} name='features'  to='/features'>Features</Menu.Item>
                      <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
                        <Menu.Item as='a'>Resources</Menu.Item>
                        <Menu.Item as='a'>Login</Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={visible} >
                        <Grid stackable>
                            <Grid.Row color="yellow" columns={4}>
                                <Grid.Column textAlign="center">
                                    <Image src={transferIcon} centered size="tiny"/>
                                    <Header>Document Exchange</Header>
                                    <span className="ui black text medium">Exchange documents encoded as JSON, XML, CSV, Txt, or EDI with documents that
                                        will follow a configurable life cycle including searchable long term archival,
                                        date based removal, or data lake migration. </span>
                                </Grid.Column>
                                <Grid.Column textAlign="center" >
                                    <Image src={apiIcon} centered size="tiny"/>
                                    <Header>Real-time API's</Header>
                                    <span className="ui black text medium">
                                    Manage REST and GRAPHQL based api’s including websocket based api’s to create realtime integrations between you and your partners.
                        Easy to use dashboard keeps track of API utilization by trading partner </span>
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                <Image src={analyticsIcon} centered size="tiny"/>
                                    <Header>Analytics</Header>
                                    <span className="ui black text medium">Translate data into columnr formats such as Parquet or ORC and run realtime analytics at amazing speed across all your data.
                                    Query the data efficently from redshift, or snowflake and visualize it using quicksite, or other reporting tools </span>
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image src={searchIcon} centered size="tiny"/>
                                    <Header>Transaction Search</Header>
                                    <span className="ui black text medium">Locate data quickly knowing only a few key words / or data fragments.  Utilize webhooks to fire off events
                            when periodic searches match transactions </span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment.Group>
                                        <Segment textAlign="center">
                                            <Image src={cloudProvidersIcon} centered/>
                                            <Header>Cloud Native, running on yourcloud provider of choice</Header>
                                        </Segment>
                                        <Segment textAlign="center">
                                            <Image src={serverIcon} size="small" centered/>
                                            <Header>Keep your data safe, choose where you store it including on prem, encrypted in cloud all while also making it searchable.</Header>
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row color="yellow" columns={2}>
                                <Grid.Column>
                                    <Header>
                                        Publish product information from suppliers to your e-commerce platform,
                                        as well as Amazon and Ebay all through one interface
                                    </Header>
                                </Grid.Column>
                                <Grid.Column>
                                        <Header>Consolidate all your orders into one user interface across sales channels and route them to fulfillment
                                                providers</Header>

                                    <Button color='black' size='large' ><Button.Content style={{color:'White'}}>Take a product tour</Button.Content></Button>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header size="large" textAlign="center">How it works</Header>
                                    <Image src={overview} size="huge" centered/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Grid.Column>
        </Grid.Row>
    </Grid>
    )
}

export default FeaturePage
